<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                                <a-col align='right' class="textal_l">
                                    <!--@click="() => (modalVisible = true)"-->
                                    <a-button type="primary" @click="TaskTargetOne(1)">新增卡券 </a-button>
                                    <a-modal width="700px" v-model="modalVisible" title="奖励类型">
                                        <div>
                                            <p style="text-align:center">
                                                <a-button type="primary" class="margin-r" @click="TaskTargetOne(1)" ghost>优惠券</a-button>
                                                <!--<a-button type="primary" class="margin-r" @click="TaskTargetOne(2)" ghost>实物奖品</a-button>-->
                                            </p>
                                        </div>
                                        <div slot="footer"></div>
                                    </a-modal>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table :columns="columns" rowKey="ID" class="ant-table-bordered"  :data-source="dataList" :pagination="pagination" @change="pagerChange">
                            <!--<span slot="Name" slot-scope="text,record">
                             <a @click="EditRow(record.ID)">   {{record.Name}} </a>
                            </span>-->
                            <span slot="action" slot-scope="text, record">
                                <a-button type="link" size="small" v-if="record.Status==0" @click="EditRow(record.ID)">编辑</a-button>
                                <a-button type="link" size="small" v-if="record.Status==1" @click="EditRow(record.ID)">查看</a-button>
                                <a-divider type="vertical" v-if="record.Status!=2"/>
                                <a-button type="link" size="small" v-if="record.Status==1" @click="isRelease(record)">停止</a-button>
                                 <a-button type="link" size="small" v-if="record.Status==0" @click="isRelease(record)">发布</a-button>
                                <a-button type="link" size="small"  v-if="record.Status==2"   @click="isRelease(record)">失效</a-button>
                            </span>
                            <span slot="GetCount" slot-scope="text, record">
                                <a-button type="link" size="small" @click="Detail(record.ID)">{{ record.GetCount }}</a-button>
                            </span>
                            <span slot="CheckCount" slot-scope="text, record">
                                <a-button type="link" size="small" @click="Detail(record.ID,1)">{{ record.CheckCount }}</a-button>
                            </span>
                        </a-table>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "PrizeList",
        data() {
            return {
                dataList: [],
                columns: [
                    {
                        title: "名称",
                        dataIndex: "Name",
                         scopedSlots: { customRender: 'Name' },
                    },
                    {
                        title: "类型",
                        dataIndex: "PrizeType",
                        align: "center",
                        customRender: (text, item) => {
                            var displayTxt = "";
                            if (item.PrizeType == 1)
                                displayTxt = "优惠券";
                            if (item.PrizeType == 2)
                                displayTxt = "实物奖品";
                            if (item.PrizeType == 3)
                                displayTxt = "现金返现";
                            return displayTxt;
                        }
                    },
                    {
                        title: "有效期",
                        dataIndex: "BeginTime",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.BeginTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "结束时间",
                        dataIndex: "EndTime",
                        align: "center",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.EndTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "数量",
                        dataIndex: "Count",
                        align: "center",
                    },
                    {
                        title: "已领取",
                        align: "center",
                        scopedSlots: { customRender: 'GetCount' },
                    },
                    {
                        title: "已核销",
                        align: "center",
                        scopedSlots: { customRender: 'CheckCount' },
                    },
                    {
                        title: "发布状态",
                        align: "center",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.Status) {
                                case 0:
                                    rt = '未发布';
                                    break;
                                case 1:
                                    rt = '已发布';
                                    break;
                                case 2:
                                    rt = '已失效';
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                modalVisible: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                StoreState: "0",
                StoreType: "0",
            };
        },
        methods: {
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetAwardPoolList",
                    data: {
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.dataList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            TaskTargetOne(val) {
                this.$router.push({
                    name: "Material_PrizeDetail",
                    query: { ID: -1, PrizeType: val }
                });
            },
            EditRow(val) {
                this.$router.push({
                    name: "Material_PrizeDetail",
                    query: { ID: val }
                });
            },
            Detail(val,status) {
                this.$router.push({
                    name: "M2Material_PrizeSendDetail",
                    query: { AwardPoolID: val,Status:status }
                });
            },
            SelectRow(e) {
                var id = 0;
                var type = 0;
                if (e != '0') {
                    id = e.StoreID;
                    type = e.StoreType;
                }
                if (type == 1) {
                    this.$router.push({
                        name: "Material_MomentDetail",
                        query: { id: id, type: type, rowType: 'select' }
                    });
                }
                if (type == 2) {
                    this.$router.push({
                        name: "Material_ExtensionDetail",
                        query: { id: id, type: type, rowType: 'select' }
                    });
                }
            },
            DelRow(id, title) {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '是否删除' + title,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/DelPool",
                            data: {
                                id: id
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                                self.$message.success("删除成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            isRelease(e)
            {
                var self = this;
                if(e.Status!=2){
                    self.UpdateM2ActivityAwardPoolStatus(e);
                }
            },
            UpdateM2ActivityAwardPoolStatus(e) {
                var self = this;
                var con = '';
               switch(e.Status){
                   case 0:
                      con="发布"
                   break;
                   case 1:
                      con="停止"
                   break;
                   default:
                       break;
               }

                var Status = e.Status == 0 ? 1 : 0;
                var SortNum = Status == 0?2:3;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/UpdateM2ActivityAwardPoolStatus",
                            data: {
                                ID: e.ID,
                                Status: Status,
                                SortNum:SortNum,
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.loadData();
                                self.$message.success(con + "成功");
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },

        },
        mounted() {
            this.loadData();
        }
    }
</script>



